<template>
  <div class="all">
    <AppHeader active-index="/course"></AppHeader>
    <div class="main">
      <h3 style="margin-bottom: 10px;">运动课程</h3>
<!--      <span style="font-size: 14px;">课程分类</span>-->
<!--      <el-select v-model="tt"-->
<!--                 clearable-->
<!--                 @change="onChange"-->
<!--                 size="mini" placeholder="请选择" style="margin-left: 5px;width: 120px;">-->
<!--        <el-option-->
<!--            v-for="item in ttOptions"-->
<!--            :key="item.key"-->
<!--            :label="item.label"-->
<!--            :value="item.value">-->
<!--        </el-option>-->
<!--      </el-select>-->

<!--      <span style="font-size: 14px;margin-left: 20px;">难度</span>-->
<!--      <el-select v-model="lt"-->
<!--                 clearable-->
<!--                 @change="onChange2"-->
<!--                 size="mini" placeholder="请选择" style="margin-left: 5px;width: 120px;">-->
<!--        <el-option-->
<!--            v-for="item in ltOptions"-->
<!--            :key="item.key"-->
<!--            :label="item.label"-->
<!--            :value="item.value">-->
<!--        </el-option>-->
<!--      </el-select>-->

      <div v-loading="loader">
        <el-row :gutter="50" v-for="(plan, index) of plans" :key="index" style="margin-top: 30px">
          <el-col :span="8" v-for="item of plan" :key="item.id">
            <router-link :to="{ path: '/course2', query: { id: item.id}}">
              <div class="shadow" style="border: 1px solid #e5e5e5;border-radius: 4px;transition: .3s;">
                <el-image :src="item.img" lazy style="display: block;width: 100%;height: 17.5vw;min-height:168px;object-fit: cover"/>
                <div style="background-color: #f1f1f1;border-top: 1px solid #e5e5e5;padding: 8px">
                  <h3 style="margin-bottom: 16px">{{ item.title }}</h3>
<!--                  <p style="">课程分类：{{ item.tt }}<i style="padding: 0 10px"/>难度：{{ item.lt }}<i style="padding: 0 10px"/>方式：{{ item.kt }}</p>-->
                <p style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{item.desc}}</p>
                </div>
              </div>
            </router-link>
          </el-col>
        </el-row>
      </div>
      <div style="margin-bottom: 50px;text-align: center" v-loading="loader2">
        <el-button v-if="moreShowBoolen" @click="moreShow" type="info" round
                   style="background-color: rgb(60,60,60);color: #fff;margin-top: 20px">加载更多
        </el-button>
<!--                <div v-else-if="moreShowBoolen==false" style="margin-top: 20px">没有更多了</div>-->
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Footer from "../../components/Footer";
import AppHeader from "../../components/AppHeader";

export default {
  name: "Course",
  data() {
    return {
      loader: false,
      loader2: false,
      moreShowBoolen: false,
      nowPage: 0,
      // tt: null,
      // lt: null,
      planData: [],
      // ttOptions: [],
      // ltOptions: [],
    }
  },

  computed: {
    plans() {
      const plans = []
      this.planData.forEach((item, index) => {
        const plan = Math.floor(index / 3)//3代表3条为一行，随意更改
        if (!plans[plan]) {
          plans[plan] = []
        }
        plans[plan].push(item)
      })
      return plans
    }
  },

  methods: {
    //课程分类请求
    // onChange(val) {
    //   // console.log(val)
    //   this.loader = true;
    //   this.tt = val;
    //   this.$axios.post('web-plans', {
    //     start: 0,
    //     type: this.tt,
    //   }).then(response => {
    //     console.log(response)
    //     this.loader = false;
    //     this.planData = response.data.data.plans;
    //   }).catch(error => {
    //     this.loader = false;
    //     console.log(error);
    //   });
    // },
    //难度请求
    // onChange2(val2) {
    //   // console.log(val2)
    //   this.loader = true;
    //   this.lt = val2;
    //   this.$axios.post('web-plans', {
    //     start: 0,
    //     level: this.lt,
    //   }).then(response => {
    //     console.log(response)
    //     this.loader = false;
    //     this.planData = response.data.data.plans;
    //   }).catch(error => {
    //     this.loader = false;
    //     console.log(error);
    //   });
    // },

    init() {
      this.loader = true;
      this.$axios.post('web-plans', {
        start: 0,
        type: this.tt,
        level: this.lt,
      }).then(response => {
        this.loader = false;
        // console.log(response)
        this.planData = response.data.data.plans;
      }).catch(error => {
        this.loader = false;
        console.log(error);
      });
    },

    moreShow() { // 点击查询更多
      this.loader2 = true;
      this.$axios.post('web-plans', {start: this.nowPage}).then(res => {
        this.loader2 = false;
        this.planData = this.plans.concat(res.data.data.plans);
        this.nowPage += res.data.data.plans.length;
        if (res.data.data.plans.length >= this.nowPage * 6) {
          this.moreShowBoolen = true
        } else {
          this.moreShowBoolen = false
        }
      }).catch(error => {
        this.loader2 = false;
        console.log(error);
      })
    },

    // getTypes(){
    //   this.$axios.post('web-types').then(responseBody=>{
    //     let res=responseBody.data.data.types;
    //     let i,ttOptions=[];
    //     let len=res.length;
    //     for(i = 0; i < len; i++) {
    //       ttOptions.push({'key':i, 'value':res[i].title});
    //     }
    //     this.ttOptions=ttOptions;
    //   }).catch(error=>{
    //     console.log(error)
    //   });
    // },

    // getLevels(){
    //   this.$axios.post('web-levels').then(responseBody=>{
    //     let res=responseBody.data.data.levels;
    //     let i,ltOptions=[];
    //     let len=res.length;
    //     for(i = 0; i < len; i++) {
    //       ltOptions.push({'key':i, 'value':res[i].title});
    //     }
    //     this.ltOptions=ltOptions;
    //   }).catch(error=>{
    //     console.log(error)
    //   });
    // }
  },
  components: {
    AppHeader, Footer
  },
  props: {
    activeIndex: {
      type: String
    }
  },
  mounted() {
    this.init();
    // this.getTypes();
    // this.getLevels();
  },
}
</script>

<style scoped>
.all {
  min-width: 960px;
}

.main {
  margin: 110px 5% 50px 5%;
  text-align: left;
}

.shadow:hover {
  /*transform: scale(1.05);*/
  box-shadow: 0 0 8px 3px #d7d7d7;
}

.text {
  position: absolute;
  z-index: 2;
  color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.card {
  /*height: 300px;*/
  /*background-color: #ffffff;*/
  /*padding: auto;*/
  /*margin: auto;*/
  float: left;
  width: 23.3%;
  margin: 20px 5%;
}

.card:hover {
  /*height: 300px;*/
  /*background-color: #ffffff;*/
  /*padding: auto;*/
  /*margin: auto;*/
  float: left;
  width: 23.3%;
  margin: 20px 5%;
  transform: scale(1.05);
}

.card .img {
  /*height: 200px;*/
  /*height: auto;*/
  height: 200px;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
  /*text-align: left;*/
  /*max-width: 100%;*/
  display: block;
}

.background {
  background: #f1f1f1;
  width: 100%;
  padding-bottom: 5px;
}

.background h4 {
  margin: 0;
  padding: 5px 0 20px 5px;
}

.background p {
  font-size: 10px;
  /*padding: 5px;*/
  color: #848484;
}

a, a:hover, a:active, a:visited, a:link, a:focus {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none;
  background: none;
  text-decoration: none;
  color: #3e3e3e;
}
</style>